import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  static targets = ['overlay']

  open() {
    var overlay = document.getElementById('fullscreen-tour-overlay');
    overlay.classList.remove('d-none');
    setTimeout(function() {
      overlay.classList.add('fade-in');
    }, 800);
  }

}
